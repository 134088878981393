import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, AudioAlbums, Pagination } from "../components"

const AudioAlbumsPage = ({ data, pageContext }) => {
  const {
    allContentfulAudioAlbum: { nodes: albums },
  } = data

  // console.log("audioAlbums.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <AudioAlbums title="Hanganyagok" albums={albums} page />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-dark-5);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query AudioAlbumsQuery($skip: Int, $limit: Int) {
    allContentfulAudioAlbum(
      limit: $limit
      skip: $skip
      sort: { fields: audioPublished, order: DESC }
    ) {
      totalCount
      nodes {
        contentful_id
        audioAlbumTitle
        performer {
          performerName
        }
        publisher {
          publisherName
        }
        audioPublished
        audioAlbumText {
          childMarkdownRemark {
            html
            excerpt(format: HTML, pruneLength: 100)
          }
        }
        audioAlbumImage {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
        category {
          categoryName
        }
      }
    }
  }
`

export default AudioAlbumsPage
